import * as React from 'react';
import { Helmet } from 'react-helmet';
import Layout from "../../../Components/Layout"
import { FooterComponent } from '../../../Components/FooterComponent';
import TopicsSidebar from '../../../modules/Support/components/TopicsSidebar'
import HeaderComponent from '../../../Components/HeaderComponent';


const indexPage = () => {
  return (
    <>
      <Helmet title="Product Topics" defer={false} />
      <Layout name="support"  >
        <title>Product Topics</title>
        <HeaderComponent hasProduct/>
        <section className='support__content container'>
         <div className='row'>
           <div className='col-sm-12 col-md-4 col-lg-4'>
             <TopicsSidebar />
           </div>
           <div className='col-sm-12 col-md-8 col-lg-8'>
             <h1> Content</h1>
           </div>
         </div>
        </section>
        <FooterComponent />
      </Layout>
    </>
  );
};

export default indexPage;