import React from 'react'
// import { useLocation } from '@reach/router';
import { Link } from 'gatsby';



const TopicsSidebar = () => {

    // const location = useLocation()
    // let id = location.pathname.split('/')[3];

    const products = [
        {
            'id': 1,
            'icon': '/products/trip.png',
            'name': 'Learning Management System',
            'url':  '',
        },
        {
            'id': 2,
            'icon':'/products/cva.png',
            'name': 'Human Capital Management',
            'url': ''
        },
        {
            'id': 3,
            'icon': '/products/cva.png',
            'name': 'Oil & Gas Transportation Suite',
            'url': ''
        }
        
    ]

  return (
    <div className='topic'>
       <div className='topic__content'>
         <ul>
         {
             products.map((item, index) => (
                <Link to={item.url} key={index}>
                  <li  className={`${item.id === 1 ? 'active' : ''}`}>{item.name}</li>
                </Link>
             ))
         }
         </ul>
       </div>
    </div>
  )
}

export default TopicsSidebar